import { EUserActionType } from '@/store';
import { ERouteNames } from '@/router';
import { defineComponent } from 'vue';
import type { AxiosResponse } from 'axios';

const initialState: IInitialState = {
	email: '',
	password: '',
	errorMessage: null,
	isProcessing: false
};

export default defineComponent({
	name: 'AuthView',
	data(): IInitialState {
		return { ...initialState };
	},
	computed: {
		isAuthorized() {
			return Boolean(this.$store.getters.isAuthorized);
		},
		authType() {
			return ERouteNames[this.$route.name as keyof typeof ERouteNames];
		}
	},
	watch: {
		isAuthorized: 'onAuthorizeChange',
		authType: 'resetState'
	},
	methods: {
		onSubmit() {
			const actionName = this.getActionName();

			if (!actionName) {
				return;
			}

			const userData = {
				email: this.email,
				password: this.password
			};

			this.$data.isProcessing = true;

			this.$store.dispatch(actionName, userData)
				.catch(({ response }: { response: AxiosResponse }) => {
					this.$data.errorMessage = response.data;
				}).finally(() => {
					this.$data.isProcessing = false;
				});
		},
		onAuthorizeChange() {
			if (this.isAuthorized) {
				this.$router.push({ name: ERouteNames.Home });
			}
		},
		resetState() {
			Object.assign(this.$data, { ...initialState });
		},
		getActionName() {
			switch (this.authType) {
				case ERouteNames.Login:
					return EUserActionType.LOGIN;
				case ERouteNames.SignUp:
					return EUserActionType.SIGN_UP;
				default:
					return null;
			}
		}
	}
});

interface IInitialState {
	email: string;
	password: string;
	errorMessage: string | null;
	isProcessing: boolean;
}
