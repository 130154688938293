import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b448dcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card p-5" }
const _hoisted_2 = { class: "has-text-left" }
const _hoisted_3 = {
  key: 0,
  class: "title is-5 mb-5 has-text-centered"
}
const _hoisted_4 = { class: "field mt-4" }
const _hoisted_5 = {
  key: 0,
  class: "help is-danger m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SField = _resolveComponent("SField")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("fieldset", _hoisted_2, [
        (_ctx.authType)
          ? (_openBlock(), _createElementBlock("legend", _hoisted_3, _toDisplayString(_ctx.authType), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_SField, {
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          type: "email",
          "left-icon-class-name": "bi bi-envelope",
          placeholder: "e.g. alex@example.com",
          name: "email",
          label: "Email",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_SField, {
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          type: "password",
          "left-icon-class-name": "bi bi-shield-lock",
          placeholder: "*******",
          minlength: "5",
          name: "password",
          label: "Password",
          required: ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SButton, {
          color: "link",
          "is-processing": _ctx.isProcessing,
          "button-text": "Submit",
          type: "submit",
          "full-width": ""
        }, null, 8, ["is-processing"]),
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ]))
}